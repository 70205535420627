import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/josh/Code/hub/joshayoung_website/src/components/layout-markdown.js";
import DefinitionsOutput from "../output/definitions-output";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Programming Definitions`}</h1>
    <p>{`These are some of the terms I have been introduced to over the years. In an effort to remember these and have a convenient place to reference, I have added them to this page.`}</p>
    <DefinitionsOutput mdxType="DefinitionsOutput" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      